<!-- 组织架构 -->
<template>
  <div class="organize-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#0094EE',
        title: '通讯录',
        txtColor: '#fff',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="main-wrapper">
      <div class="name" v-if="curDepartmentCode == ''">
        <p class="one">公司组织架构</p>
      </div>
      <div class="name" v-if="curDepartmentCode != ''">
        <p class="one">
          {{ curDepartmentName }}
        </p>
      </div>
      <div class="list-box">
        <div v-if="departmentList.length > 0">
          <div
            v-for="(item, i) in departmentList"
            :key="i"
            :title="item.departmentName"
            @click="handleItem(item)"
            :class="['item', item.type === 1 ? 'item-none' : '']"
          >
            <div class="department" v-if="item.type === 0">
              <div class="left-box">
                <span
                  :class="[i == 0 ? 'subscript-empty' : 'subscript']"
                ></span>
                <span class="item-name">{{ item.departmentName }}</span>
              </div>
              <van-icon name="arrow" />
            </div>
            <div class="personal" v-if="item.type === 1">
              <span class="react-name">{{
                item.departmentName | sclieName
              }}</span>
              <div class="right-box">
                <p class="staff-name">{{ item.departmentName }}</p>
                <p class="staff-duty">{{ item.staffDuty }}</p>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
import { getMyStatffInfo } from "@/api/setting/index.js";
import { getList } from "@/api/addressList/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      departmentList: [],
      userInfo: null,
      curDepartmentCode: "",
      curDepartmentName: "",
      names: [],
    };
  },
  filters: {
    sclieName(name) {
      if (!name) return "--";

      let len = name.length;
      let s = 0;
      if (len > 2) {
        s = len - 2;
      }
      return name.substring(s, len);
    },
  },

  watch: {},

  components: { TopWrapper },

  computed: {},

  mounted() {
    this.getUserInfo();
    this.getDepartmentList(this.curDepartmentCode);
  },

  methods: {
    handleItem(item) {
      if (item.type === 0) {
        this.curDepartmentCode = item.departmentCode;
        this.names.push(item.departmentName);
        this.curDepartmentName = this.names.toString().replaceAll(",", "/");
        this.$router.push({
          path: "/organize",
          query: {
            id: item.departmentCode,
            name: this.curDepartmentName,
          },
        });
      } else if (item.type === 1) {
        this.$router.push({
          path: "/personal",
          query: {
            id: item.departmentCode,
            name: this.curDepartmentName,
          },
        });
      }
    },
    /* 获取部门 */
    getDepartmentList(code) {
      getList({ departmentCode: code }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.departmentList = data;
          }
        }
      });
    },
    getUserInfo() {
      getMyStatffInfo().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.userInfo = data;
            this.curDepartmentName = this.userInfo.rootDepartmentName;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.organize-page {
  width: 100%;
  min-height: 100%;
  height: auto;
  background: #fff;
  position: relative;
  padding-bottom: 0.2rem;
  .main-wrapper {
    width: 100%;
    background: #fafafa;
    .name {
      width: 100%;
      height: 0.4rem;
      padding: 0.3rem;
      .one {
        font-size: 0.26rem;
        font-weight: 400;
        color: #999999;
      }
    }
    .list-box {
      margin-top: 0.3rem;
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 0.4rem 0.4rem 0 0;
      .top-name {
        font-size: 0.3rem;
        font-weight: 400;
        color: #333333;
      }
      .item {
        width: 100%;
        align-items: center;
        padding: 0.3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 0.01rem solid #e5e5e5;
        .department {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .left-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            .subscript {
              width: 0.16rem;
              height: 0.16rem;
              border: 0.2rem solid #999999;
              border-width: 0 0 0.01rem 0.01rem;
            }
            .item-name {
              margin-left: 0.11rem;
              font-size: 0.3rem;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
      .item-none {
        border: none;
      }

      .personal {
        display: flex;
        flex-direction: row;
        align-items: center;
        .react-name {
          width: 0.72rem;
          height: 0.72rem;
          background: #0094ee;
          border-radius: 0.16rem;
          text-align: center;
          line-height: 0.72rem;
          color: #fff;
        }
        .right-box {
          margin-left: 0.24rem;
          .staff-name {
            font-size: 0.3rem;
            font-weight: 400;
            color: #333333;
            margin-bottom: 0.11rem;
          }
          .staff-duty {
            font-size: 0.26rem;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
